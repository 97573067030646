import React from "react"
import { Wrapper, Container } from "../styles"
import SEO from "../components/seo"
import { GatsbyImage, IGatsbyImageData, getImage } from "gatsby-plugin-image"
import { graphql } from "gatsby"

const SignupPage = ({ data }) => {
  const handleLoad = () => {
    window.scrollTo(0,0)
  }
  
  return (
    <>
      <SEO
        title="Become a Rachio Pro"
        description="Apply to become a Rachio Pro for extended warranties, exclusive training, adaptable marketing materials and priority with our live support team."
        pathname="/signup/"
        robots="index, nofollow"
      />
      <Container className="margins no-top-margin">
        <Wrapper>
          <div className="bottom-margin">
            <GatsbyImage
              image={getImage(data.banner.childImageSharp.gatsbyImageData) as IGatsbyImageData}
              alt="Rachio 3 Pro Series controller"
              objectFit="contain"
            />
          </div>

          <iframe 
            onLoad={handleLoad}
            src="https://docs.google.com/forms/d/e/1FAIpQLSdmflfPTD2GnXUBRaszpNLTLWE7KVnG8Pf3eyuG6LrEkfYwtQ/viewform?embedded=true" 
            width="100%" 
            height="1440" 
            frameBorder={0}
            marginHeight={0}
            marginWidth={0}
          >
            Loading…
          </iframe>
        </Wrapper>
      </Container>
    </>
  )
}

export const query = graphql`
  query {
    banner: file(relativePath: { eq: "pages/signup/pro-introduce-yourself-banner.jpeg"}){
      ...fullFluidImage
    }
  }
`

export default SignupPage
